import pdf from '@/mixins/pdf'
import formatter from '@/mixins/formatter'

export default {
  mixins: [pdf, formatter],
  data: () => ({
    pdfRevolvingFund: {
      date: null,
      funds: []
    }
  }),

  methods: {

    previewRevolvingFund (
      pdfRevolvingFund = {
        date: null,
        funds: []
      }
    ) {
      this.pdfRevolvingFund = pdfRevolvingFund
      //   const noBorder = [false, false, false, false]
      //   const borderRight = [false, false, false, true]
      return this.pdfMake(
        {
          header: this.PDFRevolvingFundHeader(),
          content: [
            this.PDFRevolvingFundDetails(),
            this.PDFRevolvingFundSignatories()
          ],
          styles: {
            PDFRevolvingFundTableHeader: {
              alignment: 'center',
              fillColor: '#000000',
              color: 'white',
              bold: true
            },
            ApproverName: {
              margin: [0, 18, 0, 6],
              alignment: 'left',
              bold: true
            },
            ApproverDateTime: {
              fontSize: 7,
              bold: true
            },
            ApproverStatus: {
              alignment: 'right',
              color: 'green',
              fontSize: 7,
              bold: true
            }
          }
        },
        {
          pageSize: 'LEGAL',
          pageMargins: [10, 60, 10, 10],
          pageOrientation: 'landscape',
          fontSize: 8
        },
        {
          title: 'Replenishment',
          author: '',
          creator: '',
          subject: ''
        }
      )
    },

    PDFRevolvingFundHeader: () => [
      {
        margin: [0, 15, 0, 0],
        columns: [
          {
            layout: 'noBorders',
            table: {
              widths: ['*'],
              body: [
                [{ text: 'ALJAY AGRO-INDUSTRIAL SOLUTIONS, INCORPORATED', alignment: 'center', fontSize: 10 }],
                [{ text: 'Purok Maligaya, National Hwy, Batal, Santiago City, Isabela, Philippines, 3311', alignment: 'center', fontSize: 7 }],
                [{ text: 'https://aljayplantingdreams.com', alignment: 'center', fontSize: 7 }]
              ]
            }
          }
        ]
      }
    ],

    PDFFormatToRevolvingFund (account, particulars, requestedBy, voucherNumber, amount, remarks) {
      return [
        { text: account, alignment: 'left' },
        { text: particulars.replace(/(\r\n|\n|\r)/gm, '; '), alignment: 'left' },
        { text: requestedBy, alignment: 'left' },
        { text: voucherNumber, alignment: 'center' },
        { text: amount, alignment: 'right' },
        { text: remarks, alignment: 'left' }
      ]
    },

    PDFRevolvingFundDetails () {
      let total = 0
      const funds = this.pdfRevolvingFund.funds.map(fund => {
        const approvable = fund.approvable
        const requester = `${approvable.requestor.first_name} ${approvable.requestor.last_name}`
        total += approvable.amount
        return this.PDFFormatToRevolvingFund(
          'Cash Advance',
          approvable.particulars,
          requester,
          approvable.voucher.voucher_number,
          this.numberFormatter(approvable.amount),
          approvable.remarks
        )
      })
      return {
        table: {
          headerRows: 1,
          widths: ['7%', '38%', '15%', '10%', '10%', '20%'],
          body: [
            [
              { text: 'ACCOUNT', alignment: 'left', bold: true },
              { text: 'PARTICULARS', alignment: 'center', bold: true },
              { text: 'RECEIVED BY', alignment: 'left', bold: true },
              { text: 'CV NUMBER', alignment: 'center', bold: true },
              { text: 'AMOUNT', alignment: 'center', bold: true },
              { text: 'REMARKS', alignment: 'center', bold: true }
            ],
            ...funds,
            [
              { text: 'TOTAL', alignment: 'left', bold: true, colSpan: 5 },
              '',
              '',
              '',
              '',
              { text: this.numberFormatter(total), alignment: 'right' }
            ],
            [
              { text: 'TOTAL ENDING BALANCE', alignment: 'left', bold: true, colSpan: 5 },
              '',
              '',
              '',
              '',
              { text: ' ', alignment: 'right' }
            ],
            [
              { text: 'UNREPLENISHED', alignment: 'left', bold: true, colSpan: 5 },
              '',
              '',
              '',
              '',
              { text: ' ', alignment: 'right' }
            ],
            [
              { text: 'CASH ON HAND', alignment: 'left', bold: true, colSpan: 5 },
              '',
              '',
              '',
              '',
              { text: ' ', alignment: 'right' }
            ],
            [
              { text: 'OVER / SHORT', alignment: 'left', bold: true, colSpan: 5 },
              '',
              '',
              '',
              '',
              { text: ' ', alignment: 'right' }
            ],
            [
              { text: 'REVOLVING FUND', alignment: 'left', bold: true, colSpan: 5 },
              '',
              '',
              '',
              '',
              { text: ' ', alignment: 'right' }
            ]
          ]
        }
      }
    },

    PDFRevolvingFundSignatories () {
      return {
        columns: [
          {
            width: '70%',
            margin: [0, 10],
            layout: 'noBorders',
            table: {
              // headerRows: 1,
              widths: ['33.333%', '33.333%', '33.333%'],
              body: [
                [
                  this.PDFRevolvingFundSignatory('Prepared by:', this.$store.getters['auth/user'].name, 'Disbursement Associate', ' '),
                  this.PDFRevolvingFundSignatory('Checked by:', 'Joni A.Yusongco', 'Disbursement Head', ' '),
                  this.PDFRevolvingFundSignatory('Approved by:', 'Nikko R. Bayang, CPA', 'Financial Operation Manager', ' ')
                ]
              ]
            }
          }
        ]
      }
    },

    PDFRevolvingFundSignatory (type, name, dateTime, status = 'APROVED') {
      const noBorder = [false, false, false, false]
      return {
        margin: [0, 0, 50, 50],
        border: noBorder,
        stack: [
          { text: type },
          { text: name, style: 'ApproverName' },
          { canvas: [{ type: 'line', x1: 0, y1: -5, x2: 176, y2: -5, lineHeight: 1, lineWidth: 1, color: 'black' }] },
          {
            columns: [
              { text: dateTime, width: '60%', style: 'ApproverDateTime' },
              { text: status, width: '40%', style: 'ApproverStatus' }
            ]
          }
        ]
      }
    }
  }

}
